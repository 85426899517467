import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for validation
import { useMobile } from 'MobileContext';
import { Box, Typography, Button, useTheme } from '@mui/material';
import Container from 'common/Container';
import { styled } from '@mui/system';
import East from '@mui/icons-material/East';
import imageSrc from 'images/hosting-server.png';

const ParallaxContainer = styled(Box)({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  zIndex: -1,
});

const ParallaxContent = styled('img')(({ theme }) => ({
  willChange: 'transform',
  transition: 'transform 0.3s ease-out',
  objectFit: 'cover',
  transform: 'translateY(0)',
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
    height: 'auto',
  },
}));

const Hero = ({ handleOpenModal }) => {
  const theme = useTheme();
  const isMobile = useMobile(); 
  const parallaxRef = useRef(null);

  const handleScroll = () => {
    if (parallaxRef.current) {
      const scrollY = window.scrollY;
      parallaxRef.current.style.transform = `translateY(${-scrollY * (isMobile ? 0.2 : 0.5)}px)`;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  return (
    <Box height={'100vh'} paddingTop={isMobile ? '10%' : '5%'} sx={{ overflow: 'hidden' }}>
      <Box sx={{ boxSizing: 'border-box' }}>
        <Container>
          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} gap={isMobile ? '1.5rem' : '2rem'}>
            <Typography variant={isMobile ? 'h3' : 'h1'} align={'center'}>
              Le <Box component={'span'} color={'#00f'}>service complet</Box> pour{isMobile ? '\u00A0' : (<br />)}vos WordPress
            </Typography>
            <Typography
              variant={isMobile ? 'body2' : 'body1'}
              align={'center'}
              maxWidth={isMobile ? '90%' : '60%'}
              margin={'0 auto'}
            >
              Votre site, notre expertise en hébergement et en automatisation prenant en charge tous les aspects techniques de votre présence numérique, jusqu’à l’optimisation de vos processus d’affaires.
            </Typography>
            <Box display={'inline-flex'} justifyContent={'center'}>
              <Button
                variant="contained"
                color="primary"
                size={isMobile ? 'medium' : 'large'}
                onClick={handleOpenModal}
                endIcon={<East />} 
              >
                Analyse gratuite
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box position={'relative'}>
        <ParallaxContainer ref={parallaxRef}>
          <ParallaxContent
            src={imageSrc}
            alt="Server"
            theme={theme}
            style={{
              ...(isMobile ? {
                width: '105vw', 
                height: 'auto', 
              } : {
                width: '68vw', 
              }),
            }}
          />
        </ParallaxContainer>
      </Box>
    </Box>
  );
};

// Add PropTypes validation
Hero.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
};

export default Hero;