import React from 'react';
import { Typography, Box, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent } from '@mui/lab';
import { 
  RocketLaunchOutlined,
  CodeOutlined,
  AccountTreeOutlined,
  DynamicFormOutlined
} from '@mui/icons-material';

const StyledTimelineConnector = styled(TimelineConnector)(({ theme }) => ({
  backgroundColor: '#00f',
  width: 8,
  [theme.breakpoints.down('sm')]: {
    width: 4,
  },
}));

const StyledIcon = styled('div')(({ theme }) => ({
  color: '#2d3748',
  fontSize: '12rem',

  [theme.breakpoints.down('md')]: {
    fontSize: '8rem',
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '5rem',
    marginBottom: theme.spacing(2),
  },
}));

const StyledTimelineContent = styled(TimelineContent)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}));

const StyledTimelineItem = styled(TimelineItem)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    '&:before': {
      display: 'none',
    },
  },
}));

const timelineData = [
  {
    title: 'Analyse',
    description: 'Nous commençons par comprendre vos processus actuels et identifier les opportunités d’automatisation. Cette étape inclut une analyse approfondie pour déterminer où l’automatisation peut avoir le plus grand impact.',
    icon: AccountTreeOutlined,
    animation: 'fade-left'
  },
  {
    title: 'Conception',
    description: 'Nous concevons des workflows automatisés sur mesure, adaptés à vos besoins spécifiques. Chaque solution est développée pour s’intégrer parfaitement à vos outils existants.',
    icon: CodeOutlined,
    animation: 'fade-right'
  },
  {
    title: 'Déploiement',
    description: 'Une fois la conception validée, nous implémentons les automatisations et les testons rigoureusement pour assurer leur bon fonctionnement et leur efficacité.',
    icon: RocketLaunchOutlined,
    animation: 'fade-left'
  },
  {
    title: 'Optimisation',
    description: 'Après le déploiement, nous surveillons les performances et effectuons des ajustements continus pour garantir que vos automatisations restent efficaces et alignées sur vos objectifs.',
    icon: DynamicFormOutlined,
    animation: 'fade-right'
  }
];

export default function CustomizedTimeline() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box paddingY={{ xs: 4, sm: 6, md: 12.5 }} overflow={'hidden'}>
      <Typography textAlign={'center'} variant={'h2'} mb={10}>
        De l’Idée à la Réalisation
      </Typography>
      <Timeline position={isMobile ? 'right' : 'alternate'}>
        {timelineData.map((item, index) => (
          <StyledTimelineItem key={index} sx={{ minHeight: { xs: '300px', md: '600px' }, mb: { xs: 3, sm: 0 } }}>
            <TimelineSeparator>
              <StyledTimelineConnector sx={{ borderRadius: index === 0 ? '2rem 2rem 0rem 0rem' : '0' }} />
              <StyledIcon as={item.icon} />
              <StyledTimelineConnector sx={{ borderRadius: index === timelineData.length - 1 ? '0rem 0rem 2rem 2rem' : '0' }} />
            </TimelineSeparator>
            <StyledTimelineContent data-aos={isMobile ? 'fade-up' : item.animation} sx={{ py: '12px', px: 2 }}>
              <Box sx={{  display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
                <Typography variant="h3" sx={{ fontSize: { xs: '1.5rem', md: '2.5rem' } }}>
                  {item.title}
                </Typography>
                <Typography component="p" sx={{ fontSize: { xs: '0.9rem', md: '1.5rem' } }}>
                  {item.description}
                </Typography>
              </Box>
            </StyledTimelineContent>
          </StyledTimelineItem>
        ))}
      </Timeline>
    </Box>
  );
}