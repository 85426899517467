import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import { MobileProvider } from './MobileContext';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';
import 'react-18-image-lightbox/style.css';
import './global.css';

const App = () => {
  return (
    <MobileProvider>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </MobileProvider>
  );
};

export default App;