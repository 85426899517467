import React, { useState, useEffect, useRef } from 'react';
import { useMobile } from 'MobileContext';
import { Box, Grid, Tabs, Tab, Typography, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { features as Hosting } from 'views/Hosting/Hosting';
import { features as Security } from 'views/Security/Security';
import { features as Maintenance } from 'views/Maintenance/Maintenance';
import { features as Integration } from 'views/Integration/Integration';

interface FeaturesProps {
  index: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children } = props;

  return (
    <div role="tabpanel">
      <Box pb={1} mt={2}>
        {children}
      </Box>
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const StyledTab = styled(Tab)({
  fontFamily: 'ClashDisplay-SemiBold',
  lineHeight: 1,
  fontWeight: 'unset',
  maxWidth: 'unset',
  width: '100%',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: 0,
});

const StyledIcon = styled('div')(({ theme }) => ({
  color: '#2d3748',
  fontSize: '2rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '5rem',
  },
}));

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const later = () => {
      clearTimeout(timeout);
      func.apply(this, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const Features: React.FC<FeaturesProps> = ({ index }) => {
  const isMobile = useMobile();
  const [featuresList, setFeaturesList] = useState([]);

  useEffect(() => {
    const allFeatures = [Hosting, Integration, Security, Maintenance];
    const foundIndex = allFeatures.findIndex((featureSet) => featureSet.index === index);

    if (foundIndex !== -1) {
      const reorderedFeatures = [
        allFeatures[foundIndex],
        ...allFeatures.slice(0, foundIndex),
        ...allFeatures.slice(foundIndex + 1),
      ];
      setFeaturesList(reorderedFeatures);
    } else {
      setFeaturesList(allFeatures);
    }
  }, [index]);

  const [value, setValue] = useState(0);
  const tabRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const anchorRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const handleTabClick = (index: number) => {
    setValue(index);
    scrollToTab(index);
  };

  const scrollToTab = (index: number) => {
    const ref = tabRefs[index];
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    window.requestAnimationFrame(() => {
      const visibleTabIndex = anchorRefs.findIndex((ref) => {
        const rect = ref.current?.getBoundingClientRect();
        return rect && rect.top >= 0 && rect.bottom <= window.innerHeight;
      });

      if (visibleTabIndex !== -1 && visibleTabIndex !== value) {
        setValue(visibleTabIndex);
      }
    });
  };

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 100);
    window.addEventListener('scroll', debouncedHandleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [value]);

  return (
    <>
      <Box
        position='sticky'
        top={78}
        zIndex={1}
        bgcolor='white'
        display={'flex'}
      >
        <Tabs
          value={value}
          aria-label=''
          variant='scrollable'
          scrollButtons={false}
          indicatorColor='transparent'
        >
          {featuresList.map((content, index) => (
            <StyledTab
              key={index}
              label={
                <Grid container alignItems='center' sx={{ width: '100%', paddingY: 1 }}>
                  <Grid item sx={{ width: '100%' }}>
                    <Typography variant='h2' textAlign='left' color='#2d3748'>
                      {content.title}
                    </Typography>
                  </Grid>
                </Grid>
              }
              {...a11yProps(index)}
              onClick={() => handleTabClick(index)}
            />
          ))}
        </Tabs>
      </Box>
      <Grid item xs={12} sm={10}>
        {featuresList.map((content, index) => (
          <div ref={tabRefs[index]} key={index}>
            <div ref={anchorRefs[index]} id={`anchor-${index}`} />
            <TabPanel value={value} index={index}>
              <Grid container spacing={4} marginY={3}>
                {content.list.map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index} data-aos={'fade-up'}>
                    <Box
                      display={'block'}
                      height={'100%'}
                      sx={{
                        textDecoration: 'none',
                        transition: 'all .2s ease-in-out',
                        position: 'relative',
                      }}
                    >
                      <Box
                        component={Card}
                        width={'100%'}
                        height={'100%'}
                        display={'flex'}
                        flexDirection={'column'}
                        boxShadow={0}
                        sx={{
                          position: 'relative',
                          borderRadius: 'unset',
                          paddingLeft: 0,
                          transition: 'all .2s ease',
                          '&:before': {
                            content: '""',
                            position: 'absolute',
                            left: 0,
                            top: 35,
                            height: 8,
                            width: 8,
                            borderRadius: 3,
                            backgroundColor: content.color,
                            transformOrigin: 'top',
                            transition: 'height .5s ease',
                          },
                          '&:hover': !isMobile && {
                            '&:before': {
                              height: 80,
                            },
                            '& .title': {
                              transform: 'scale(0.95)',
                              transition: 'transform .3s ease',
                            },
                            '& .subtitle': {
                              transform: 'scale(1)',
                              transition: 'transform .3s ease',
                            },
                          },
                        }}
                      >
                        <Box marginLeft={'1rem'}>
                          <StyledIcon as={item.icon} />
                          <Box
                            component={Typography}
                            variant={'h4'}
                            gutterBottom
                            fontWeight={600}
                            align={'left'}
                            className='title'
                            sx={{
                              transform: 'scale(1)',
                              transformOrigin: 'left',
                              transition: 'transform .3s ease',
                            }}
                          >
                            {item.title}
                          </Box>
                          <Typography
                            align={'left'}
                            variant={'body2'}
                            color='textSecondary'
                            className='subtitle'
                            sx={{
                              fontSize: '1rem',
                              transform: 'scale(0.95)',
                              transformOrigin: 'left top',
                              transition: 'all .5s',
                            }}
                          >
                            {item.subtitle}
                          </Typography>
                        </Box>
                        <Box flexGrow={1} />
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
          </div>
        ))}
      </Grid>
    </>
  );
};

export default Features;