import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as RedisLogo } from 'svg/logos/redis.svg';
import { ReactComponent as VultrLogo } from 'svg/logos/vultr.svg';
import { ReactComponent as CloudflareLogo } from 'svg/logos/cloudflare.svg';
import { ReactComponent as DoLogo } from 'svg/logos/digital-ocean.svg';
import { ReactComponent as LinodeLogo } from 'svg/logos/linode.svg';
import { ReactComponent as AwsLogo } from 'svg/logos/aws.svg';
import { ReactComponent as LetsEncryptLogo } from 'svg/logos/lets-encrypt.svg';

const Partners = () => {
  const logos = [
    { component: <RedisLogo />, alt: 'Redis' },
    { component: <VultrLogo />, alt: 'Vultr' },
    { component: <CloudflareLogo />, alt: 'Cloudflare' },
    { component: <DoLogo />, alt: 'Digital Ocean' },
    { component: <LinodeLogo />, alt: 'Linode' },
    { component: <AwsLogo />, alt: 'AWS' },
    { component: <LetsEncryptLogo />, alt: 'Let\'s Encrypt' },
  ];

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      {logos.map((item, i) => (
        <Box
          key={i}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexBasis={'auto'}
          sx={{
            padding: '1.25rem',
            '& svg': {
              height: '2rem',
              width: 'auto',
              objectFit: 'contain',
            },
          }}
        >
          {item.component}
        </Box>
      ))}
    </Box>
  );
};

export default Partners;