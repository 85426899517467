import React, { useState, useRef, useEffect } from 'react';
import CountUp from 'react-countup';
import { styled } from '@mui/system';
import { Typography, Grid, Box, useTheme, useMediaQuery } from '@mui/material';
import Hero from 'common/Hero-silde';

const useOnScreen = (options) => {
  const ref = useRef();
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return [ref, isVisible];
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: theme.spacing(2),
  '&:not(:last-child)::after': {
    content: '""',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: '1px',
    backgroundColor: '#D1D1E1',
  },
  [theme.breakpoints.down('md')]: {
    '&:not(:last-child)::after': {
      display: 'none',
    },
  },
}));

const Specs = () => {
  const [relativeHeight, setRelativeHeight] = useState(0);
  const absoluteBoxRef = useRef(null);
  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMd = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLg = useMediaQuery(theme.breakpoints.up('md'));

  const getPaddingY = () => {
    if (isXs) return 4 * 8;
    if (isSm) return 6 * 8;
    if (isMd) return 10 * 8;
    return 12 * 8;
  };

  const updateHeightAndFont = () => {
    if (absoluteBoxRef.current) {
      const absoluteHeight = absoluteBoxRef.current.offsetHeight;
      const paddingY = getPaddingY();
      const totalHeight = absoluteHeight + paddingY;

      if (isXs) {
        setRelativeHeight(totalHeight * 0.9);
      } else if (isSm) {
        setRelativeHeight(totalHeight * 0.9);
      } else if (isMd) {
        setRelativeHeight(totalHeight * 0.75);
      } else if (isLg) {
        setRelativeHeight(totalHeight * 0.5);
      }
    }
  };

  useEffect(() => {
    updateHeightAndFont();
    window.addEventListener('resize', updateHeightAndFont);

    return () => {
      window.removeEventListener('resize', updateHeightAndFont);
    };
  }, [isXs, isSm, isMd, isLg]);

  const hero = {
    typewriter: [
      'fiable', 'rapide', 'sécurisé', 'performant', 'scalable',
      'robuste', 'flexible', 'innovant', 'optimisé', 'puissant',
      'évolutif', 'réactif', 'adaptable', 'efficace', 'stabilisé'
    ],
    dot: ',',
    titleLine1: 'Hébergement',
    titleLine2: 'adapté à tous vos besoins 🚀',
    text: [
      'Chez Moïto, chaque plan est conçu pour garantir la stabilité, la rapidité et la sécurité de votre site web, peu importe sa taille ou son ambition.',
      'Notre infrastructure avancée s’adapte à vos besoins spécifiques, vous offrant ainsi la tranquillité d’esprit pour vous concentrer sur vos activités.',
      'Que vous gériez une simple vitrine ou un environnement de production complet, notre service d’hébergement est optimisé pour des performances maximales.'
    ],
    cta: '',
    icon: '',
    color: '#FFF',
  };

  const specsData = [
    {
      title: 'Vitrine',
      features: [
        { label: '{num} Go de stockage NVMe', num: 80 },
        { label: '{num} Processeurs', num: 2 },
        { label: '{num} Go mémoire vive', num: 4 },
        { label: 'Trafic illimité' },
      ],
    },
    {
      title: 'Boutique',
      features: [
        { label: '{num} Go de stockage NVMe', num: 160 },
        { label: '{num} Processeurs', num: 4 },
        { label: '{num} Go mémoire vive', num: 8 },
        { label: 'Trafic illimité' },
      ],
    },
    {
      title: 'Entreprise',
      features: [
        { label: '{num} Go de stockage NVMe', num: 320 },
        { label: '{num} Processeurs', num: 8 },
        { label: '{num} Go mémoire vive', num: 16 },
        { label: 'Trafic illimité' },
      ],
    },
    {
      title: 'Production',
      features: [
        { label: '{num} Go de stockage NVMe', num: 400 },
        { label: '{num} Processeurs', num: 8 },
        { label: '{num} Go mémoire vive', num: 32 },
        { label: 'Trafic illimité' },
      ],
    },
  ];

  const renderLabelWithCountUp = (label, num) => {
    const [ref, isVisible] = useOnScreen({ threshold: 0.5 });

    if (label.includes('{num}')) {
      const parts = label.split('{num}');
      return (
        <>
          {parts[0].replace(/ /g, '\u00A0')}
          <span style={{ color: '#fff' }} ref={ref}>
            <CountUp start={0} end={isVisible ? num : 0} duration={2} />
          </span>
          {parts[1].replace(/ /g, '\u00A0')}
        </>
      );
    }
    return label.replace(/ /g, '\u00A0');
  };

  return (
    <Box>
      <Hero
        typewriter={hero.typewriter}
        dot={hero.dot}
        titleLine1={hero.titleLine1}
        titleLine2={hero.titleLine2}
        text={hero.text}
        cta={hero.cta}
        icon={hero.icon}
        color={hero.color}
      />
      <Box position="relative" sx={{ height: relativeHeight }}>
        <Box
          ref={absoluteBoxRef}
          sx={{
            position: 'absolute',
            backgroundColor: 'rgb(31, 31, 48)',
            borderRadius: '1rem',
            padding: '20px 6px',
            margin: '24px',
            left: 0,
            right: 0,
            top: 0,
            color: '#fed130',
            textAlign: 'center',
          }}
        >
          <Grid container>
            {specsData.map((item, i) => (
              <StyledGrid key={i} item xs={12} sm={6} md={3} gap={'1rem'}>
                <Typography variant="h3" gutterBottom sx={{ fontSize: '2rem', margin: '0.5rem' }}>
                  {item.title}
                </Typography>
                {item.features.map((feature, index) => (
                  <Typography
                    key={index}
                    variant="body2"
                    gutterBottom
                    sx={{
                      display: 'inline-flex',
                      color: 'rgb(157, 157, 173)',
                      fontSize: '1rem',
                      fontFamily: 'ClashDisplay-SemiBold',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    {feature.num ? renderLabelWithCountUp(feature.label, feature.num) : feature.label}
                  </Typography>
                ))}
              </StyledGrid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Specs;