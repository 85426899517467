import React from 'react';
import { useMobile } from 'MobileContext';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import PhoneIcon from '@mui/icons-material/Phone';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { ReactComponent as MoitoLogo } from 'svg/logos/Moito-theme.svg';

const Topbar = ({
  handleOpenModal
}) => {
  const isMobile = useMobile();

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      paddingY={1}
    >
      {/* Logo Section */}
      <Box
        display={'flex'}
        justifyContent={'flex-start'}
        alignItems={'center'}
        component="a"
        underline="none"
        href="/"
        title="moito"
        height={'100%'}
        width={'6rem'}
      >
        <MoitoLogo height={'100%'} width={'100%'} />
      </Box>

      {/* Contact and Button Section */}
      <Box display="flex" alignItems={'center'}>
        {/* Phone Section */}
        <Box marginX={2}>
          {isMobile ? (
            <IconButton href="tel:4184786600" color="primary">
              <PhoneIcon />
            </IconButton>
          ) : (
            <Link
              underline="none"
              component="a"
              href="tel:4184786600"
              color="textPrimary"
              fontFamily={'ClashDisplay-Bold'}
            >
              (418) 478-6600
            </Link>
          )}
        </Box>

        {/* Audit Button Section */}
        <Box>
          {isMobile ? (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleOpenModal}
              startIcon={<AssessmentIcon />}
              sx={{
                fontSize: '0.75rem',
                padding: '4px 8px',
              }}
            >
              Audit
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleOpenModal}
            >
              Obtenir un audit
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  setThemePalette: PropTypes.func.isRequired,
  onSidebarOpen: PropTypes.func,
  paletteType: PropTypes.string.isRequired,
};

export default Topbar;