import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const Container = ({ children, ...rest }) => (
  <Box
    maxWidth="1200px"
    width="100%"
    margin="0 auto"
    paddingX={3}
    paddingY={{ xs: 4, sm: 6, md: 10 }}
    {...rest}
  >
    {children}
  </Box>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;