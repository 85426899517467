import { responsiveFontSizes } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import palette from './palette';
import ClashDisplayExtraLight from '../fonts/ClashDisplay/ClashDisplay-Extralight.woff2';
import ClashDisplayLight from '../fonts/ClashDisplay/ClashDisplay-Light.woff2';
import ClashDisplayRegular from '../fonts/ClashDisplay/ClashDisplay-Regular.woff2';
import ClashDisplaySemiBold from '../fonts/ClashDisplay/ClashDisplay-Semibold.woff2';
import ClashDisplayBold from '../fonts/ClashDisplay/ClashDisplay-Bold.woff2';
import JetBrainsMonoBold from '../fonts/JetBrainMono/JetBrainsMono-Bold.woff2';
import JetBrainsMonoLight from '../fonts/JetBrainMono/JetBrainsMono-Light.woff2';
import JetBrainsMonoRegular from '../fonts/JetBrainMono/JetBrainsMono-Regular.woff2';

const getTheme = (mode, paletteType) =>
  responsiveFontSizes(
    createTheme({
      palette: palette(mode, paletteType),
      layout: {
        contentWidth: 1236,
      },
      shadows: shadows(mode),
      typography: {
        fontFamily: 'ClashDisplay-Regular',
        fontWeight: 700,
        lineHeight: 1,
      },
      zIndex: {
        appBar: 1200,
        drawer: 1300,
      },
      components: {
        MuiTypography: {
          styleOverrides: {
            h1: {
              fontFamily: 'ClashDisplay-Bold',
              fontWeight: 700,
              lineHeight: 1,
              fontSize: '2.5rem',
              '@media (min-width:600px)': {
                fontSize: '3rem',
              },
              '@media (min-width:900px)': {
                fontSize: '3.25rem',
              },
              '@media (min-width:1200px)': {
                fontSize: '4rem',
              },
            },
            h2: {
              fontFamily: 'ClashDisplay-Bold',
              fontWeight: 700,
              lineHeight: 1,
              fontSize: '2rem',
              '@media (min-width:600px)': {
                fontSize: '2.5rem',
              },
              '@media (min-width:900px)': {
                fontSize: '3rem',
              },
              '@media (min-width:1200px)': {
                fontSize: '3.5rem',
              },
            },
            h3: {
              fontFamily: 'ClashDisplay-Bold',
              fontWeight: 700,
              lineHeight: 1,
              fontSize: '1.75rem',
              '@media (min-width:600px)': {
                fontSize: '2rem',
              },
              '@media (min-width:900px)': {
                fontSize: '2.25rem',
              },
              '@media (min-width:1200px)': {
                fontSize: '2.75rem',
              },
            },
            h4: {
              fontFamily: 'ClashDisplay-Bold',
              fontWeight: 700,
              lineHeight: 1,
              fontSize: '1.5rem',
              '@media (min-width:600px)': {
                fontSize: '1.75rem',
              },
              '@media (min-width:900px)': {
                fontSize: '1.9rem',
              },
              '@media (min-width:1200px)': {
                fontSize: '2rem',
              },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontFamily: 'ClashDisplay-SemiBold',
              textTransform: 'none',
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              fontFamily: 'ClashDisplay-SemiBold',
              textTransform: 'none',
            },
          },
        },
        MuiCssBaseline: {
          styleOverrides: `
            @font-face {
              font-family: 'ClashDisplay-ExtraLight';
              font-style: normal;
              font-display: swap;
              src: local('ClashDisplay-ExtraLight'), url(${ClashDisplayExtraLight}) format('woff2');
            }
            @font-face {
              font-family: 'ClashDisplay-Light';
              font-style: normal;
              font-display: swap;
              src: local('ClashDisplay-Light'), url(${ClashDisplayLight}) format('woff2');
            }
            @font-face {
              font-family: 'ClashDisplay-Regular';
              font-style: normal;
              font-display: swap;
              src: local('ClashDisplay-Regular'), url(${ClashDisplayRegular}) format('woff2');
            }
            @font-face {
              font-family: 'ClashDisplay-SemiBold';
              font-style: normal;
              font-display: swap;
              src: local('ClashDisplay-SemiBold'), url(${ClashDisplaySemiBold}) format('woff2');
            }
            @font-face {
              font-family: 'ClashDisplay-Bold';
              font-style: normal;
              font-display: swap;
              src: local('ClashDisplay-Bold'), url(${ClashDisplayBold}) format('woff2');
            }
            
            /* JetBrainsMono fonts */
            @font-face {
              font-family: 'JetBrainsMono-Bold';
              font-style: normal;
              font-display: swap;
              src: local('JetBrainsMono-Bold'), url(${JetBrainsMonoBold}) format('woff2');
            }
            @font-face {
              font-family: 'JetBrainsMono-Regular';
              font-style: normal;
              font-display: swap;
              src: local('JetBrainsMono-Regular'), url(${JetBrainsMonoRegular}) format('woff2');
            }
            @font-face {
              font-family: 'JetBrainsMono-Light';
              font-style: normal;
              font-display: swap;
              src: local('JetBrainsMono-Light'), url(${JetBrainsMonoLight}) format('woff2');
            }
          `,
        },
      },
    }),
  );

export default getTheme;