import React from 'react';
import { useMobile } from 'MobileContext';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ReactComponent as MoitoLogo } from 'svg/logos/Moito-Yellow-Min.svg';
import East from '@mui/icons-material/East';

const Footer = ({ handleOpenModal }) => {
  const isMobile = useMobile();

  return (
    <Grid
      container
      sx={{
        minHeight: '500px',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {/* Section principale */}
      <Grid item xs={12}>
        <Box mb={2}>
          <Typography variant="h2" sx={{ color: '#9D9DAD' }}>
            Prêt à simplifier vos opérations ?
          </Typography>
        </Box>
        <Box display="flex">
          <Link
            underline="hover"
            component="button"
            color="#fff"
            variant={isMobile ? 'h4' : 'h2'}
            onClick={handleOpenModal}
          >
            Obtenez un audit
          </Link>
          <East sx={{ color: '#fff', marginLeft: 1, fontSize: '2.75rem' }} />
        </Box>
      </Grid>

      {/* Section de contact */}
      <Grid item xs={12} mt={5}>
        <Box mb={2}>
          <Link
            underline="hover"
            component="a"
            href="mailto:hello@moito.ca"
            color="#fff"
            variant="h6"
          >
            hello@moito.ca
          </Link>
        </Box>

        {/* Section logo et droits d'auteur */}
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          gap={5}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Link
              href="/"
              title="moito"
              sx={{ height: 24, width: 35 }}
            >
              <MoitoLogo height="100%" width="100%" />
            </Link>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="subtitle2" color="#9D9DAD" textAlign="center">
              &copy; Moïto - Solutions en ligne Inc. 2024, Tous droits réservés.
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

Footer.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
};

export default Footer;