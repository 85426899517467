import React, { useState } from 'react';
import { useMobile } from 'MobileContext';
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Container from 'common/Container';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import HighlightOffOutlined from '@mui/icons-material/HighlightOffOutlined';
import { Topbar, Footer } from './components';
import ContactMobile from 'common/ContactFormMobile';
import ContactDesktop from 'common/ContactForm';
import ConsentBanner from 'common/ConsentBanner';

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = {
  children: PropTypes.node.isRequired,
};

const Main = ({
  children,
  themeToggler,
  themeMode,
  setThemePalette,
  paletteType,
}) => {
  const isMobile = useMobile();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
    document.documentElement.style.overflow = 'hidden';
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    document.documentElement.style.overflow = '';
  };

  const handleSmoothScroll = (event) => {
    event.preventDefault();
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          height: '5rem',
          justifyContent: 'center',
        }}
        elevation={0}
      >
        <Container paddingY={{ xs: 0.5, sm: 1 }}>
          <Topbar
            handleOpenModal={handleOpenModal}
            themeMode={themeMode}
            themeToggler={themeToggler}
            setThemePalette={setThemePalette}
            paletteType={paletteType}
          />
        </Container>
      </AppBar>
      <main>
        <Box height={{ xs: 56, sm: 64 }} />
        {React.cloneElement(children, { handleOpenModal })}
        <Divider />
      </main>
      <footer>
        <Box bgcolor="#1f1f30" position="relative" zIndex={3}>
          <Container paddingY={15}>
            <Footer
              handleSmoothScroll={handleSmoothScroll}
              handleOpenModal={handleOpenModal}
            />
          </Container>
        </Box>
      </footer>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="form-mobile-title"
        aria-describedby="form-mobile-description"
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Box
          id="modal"
          paddingY={isMobile ? 10 : 0}
          paddingX={isMobile ? 2 : 0}
          sx={{
            height: '100vh',
            width: '100%',
            backgroundColor: '#252a33',
            overflowY: 'auto',
            position: 'relative',
            boxShadow: 'none',
            transition: 'transform 0.3s ease-in-out',
            transform: openModal ? 'translateX(0)' : 'translateX(100%)',
          }}
        >
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              top: isMobile ? '52px' : '16px',
              right: '16px',
              zIndex: 9999,
            }}
          >
            <HighlightOffOutlined />
          </IconButton>
          {isMobile ? <ContactMobile /> : <ContactDesktop />}
        </Box>
      </Modal>
      <ConsentBanner />
    </>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  setThemePalette: PropTypes.func.isRequired,
  paletteType: PropTypes.string.isRequired,
};

export default Main;