import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { Box } from '@mui/material';
import Container from 'common/Container';
import FeaturesCommon from 'common/Features';
import { Hero, Features, Specs, Logos, TimeLine, Partners } from './components';

const Home = ({ handleOpenModal }) => {
  return (
    <Box>
      <section>
        <Box>
          <Hero handleOpenModal={handleOpenModal} />
        </Box>
      </section>
      <section>
        <Box bgcolor={'#fafafa'}>
          <Container>
            <Partners />
          </Container>
        </Box>
      </section>
      <section>
        <Box bgcolor={'#00f'} mb={{ xs: 4, sm: 6, md: 10 }}>
          <Container>
            <Features />
          </Container>
        </Box>
      </section>
      <section>
        <Container>
          <FeaturesCommon />
        </Container>
      </section>
      <section>
        <Box bgcolor={'#fed130'} mb={20}>
          <Container>
            <Specs />
          </Container>
        </Box>
      </section>
      <section>
        <Logos />
      </section>
      <section>
        <Box>
          <Container>
            <TimeLine />
          </Container>
        </Box>
      </section>
    </Box>
  );
};

// Add PropTypes validation
Home.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
};

export default Home;