import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Snackbar, Button, Typography, Link, Box } from '@mui/material';

const ConsentBanner = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const consentGiven = Cookies.get('userConsent');
    if (!consentGiven) {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set('userConsent', 'true', { expires: 365 }); // Expiration du cookie dans 1 an
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      message={
        <Box>
          <Typography variant="body2">
            Nous utilisons des cookies pour améliorer votre expérience. En continuant, vous acceptez notre{' '}
            <Link href="/politique-de-confidentialite" color="inherit">
              politique de confidentialité
            </Link>.
          </Typography>
        </Box>
      }
      action={
        <Button color="secondary" size="small" onClick={handleAccept}>
          Accepter
        </Button>
      }
    />
  );
};

export default ConsentBanner;