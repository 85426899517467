import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';  // Import prop-types for validation
import { useTheme } from '@mui/material/styles';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { Container } from '@mui/system';
import TextTransition from 'react-text-transition';

const HeroSlide = (props) => {
  const { typewriter, dot, titleLine1, titleLine2, text, cta, icon, color } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      2000
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <>
      <Box mb={{ xs: '2rem', sm: '3rem', md: 10 }}>
        <Typography variant="h2" component="div" display="inline-block">
          <span>{titleLine1} </span>
          {typewriter && (
            <>
              <Box
                component="span"
                sx={{
                  color: color,
                  display: 'inline-flex',
                  alignItems: 'center',
                  width: 'fit-content',
                  maxWidth: '100%',
                  whiteSpace: 'nowrap',
                }}
              >
                <TextTransition springConfig={{ mass: 1, tension: 170, friction: 15 }}>
                  {typewriter[index % typewriter.length]}
                </TextTransition>
                <span style={{ color }}>{dot}</span>
              </Box>
            </>
          )}
          {titleLine2 && (
            <>
              <br />
              <span>{titleLine2}</span>
            </>
          )}
        </Typography>
      </Box>
      <Box mb="1rem">
        <Container>
          <Box>
            {text && (
              <Typography
                component="div"
                display="flex"
                flexDirection="column"
                gap="1rem"
                fontSize={{ sm: '20px', md: '24px ' }}
                lineHeight="1.67"
                maxWidth="90%"
              >
                {text.map((line, idx) => (
                  <span key={idx}>
                    {line}
                    {idx < text.length - 1 && <br />}
                  </span>
                ))}
              </Typography>
            )}
            {cta && (
              <Box
                component={Button}
                variant="outlined"
                color="primary"
                size="large"
                mt={5}
                fullWidth={!isMd}
                startIcon={icon}
              >
                {cta}
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

// Add prop-types validation
HeroSlide.propTypes = {
  typewriter: PropTypes.arrayOf(PropTypes.string).isRequired,
  dot: PropTypes.string.isRequired,
  titleLine1: PropTypes.string.isRequired,
  titleLine2: PropTypes.string,
  text: PropTypes.arrayOf(PropTypes.string),
  cta: PropTypes.string,
  icon: PropTypes.node,
  color: PropTypes.string.isRequired,
};

export default HeroSlide;