import React from 'react';
import Box from '@mui/material/Box';
import Landing from 'common/Landing';
import image1 from 'images/home-anyone.jpg';
import { BuildOutlined, WebOutlined, WebhookOutlined } from '@mui/icons-material';

const hero = {
  typewriter: [' contenues', ' processus'],
  dot: '',
  titleLine1: 'Integration de',
  titleLine2: '',
  text: 'Perfectionner votre présence en ligne grâce à une intégration harmonieuse du SEO, des services de Google, d’un WooCommerce ou de tout autre processus d’automatisation.',
  cta: '',
  icon: '',
};

const color = '#7209b7';

const partners = [
  'https://www.cloudways.com/wp-content/uploads/2022/03/cw-partner-cloudflare-logo.svg',
];

const services = {
  title: 'Solution d’hébergement performant👌',
  list: [
    {
      title: 'Digital Ocean Mutualisé',
      image: image1,
    },
    {
      title: 'CloudFlare Cloud',
      image: image1,
    },
    {
      title: 'PHP VPS',
      image: image1,
    },
    {
      title: 'Redis Dédié',
      image: image1,
    },
  ],
};

export const features = {
  title: 'Inspiration et conception',
  description: 'Our unique performance tweaks guarantee nothing but the best!',
  list: [
    {
      title: 'Agilité DevOps intégrées',
      subtitle:
        'Adoption de pratiques DevOps pour faciliter l’intégration et le déploiement continus, assurant des mises à jour rapides et fiables tout en améliorant la collaboration entre nos équipes de développement et d’opérations.',
      icon: BuildOutlined,
    },
    {
      title: 'Création web innovante',
      subtitle:
        'Nous proposons des services de développement web personnalisés pour créer des sites qui répondent précisément aux besoins de nos clients, en utilisant les technologies et frameworks les plus récents.',
      icon: WebOutlined,
    },
    {
      title: 'Automatisation intelligente',
      subtitle:
        'Nous mettons en œuvre des automatisations via API pour simplifier vos processus et accroître l’efficacité opérationnelle, tout en permettant une intégration fluide avec d’autres services et applications.',
      icon: WebhookOutlined,
    },
  ],
  partners: [],
  bgColor: '#00f',
  color: color,
  index: 4,
};

const Integration = () => {
  return (
    <Box>
      <Landing hero={hero} color={color} partners={partners} services={services} features={features.index} />
    </Box>
  );
};

export default Integration;