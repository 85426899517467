import React from 'react';
import Container from 'common/Container';
import Hero from 'common/Hero-silde';
import LogoSlider from 'common/Logo-slider';
import LogoCards from './LogoCards';

const Logos = () => {

  const hero = {
    typewriter: [
      'opérations',
      'processus',
      'performances',
      'ressources',
      'résultats',
      'stratégies',
      'systèmes',
      'initiatives',
      'projets',
      'actions',
      'solutions',
      'services'
    ],
    dot: '',
    titleLine1: 'Optimisez vos',
    titleLine2: 'avec une flexibilité infinie',
    text: [
      'Optez pour notre service d’automatisation sur mesure qui vous permet de réaliser jusqu’à 60 % d’économies par rapport aux solutions traditionnelles comme Zapier, Workato ou Tray.io.',
      'Nous concevons des scénarios, des applications et des intégrations illimitées, sans frais sur le nombre d\'action, pour une flexibilité totale adaptée à vos besoins.'
    ],
    cta: '',
    icon: '',
    color: '#7209b7',
  };

  return (
    <>
      <Container>
        <Hero
          typewriter={hero.typewriter}
          dot={hero.dot}
          titleLine1={hero.titleLine1}
          titleLine2={hero.titleLine2}
          text={hero.text}
          cta={hero.cta}
          icon={hero.icon}
          color={hero.color}
        />
      </Container>
      <LogoSlider />
      <Container>
        <LogoCards />
      </Container>
    </>
  );
};

export default Logos;