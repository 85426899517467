import React from 'react';
import Box from '@mui/material/Box';
import Landing from 'common/Landing';
import image1 from 'images/home-anyone.jpg';
import { HttpsOutlined, SecurityOutlined, ShieldOutlined } from '@mui/icons-material';

const hero = {
  typewriter: [' cybersécurié'],
  dot: ',',
  titleLine1: 'Votre',
  titleLine2: 'notre priorité',
  text: 'Protégez votre site WordPress contre les menaces potentielles avec nos solutions de cybersécurité robustes.',
  cta: '',
  icon: '',
};

const color = '#FF4141';

const partners = [
  'https://www.cloudways.com/wp-content/uploads/2019/10/cw-partner-gce-logo.svg',
  'https://www.cloudways.com/wp-content/uploads/2019/10/cw-partner-do-logo.svg',
  'https://www.cloudways.com/wp-content/uploads/2019/10/cw-partner-aws-logo.svg',
  'https://www.cloudways.com/wp-content/uploads/2019/10/cw-partner-vultr-logo.svg',
  'https://www.cloudways.com/wp-content/uploads/2019/10/cw-partner-linode-logo.svg',
  'https://www.cloudways.com/wp-content/uploads/2022/03/cw-partner-malcare-logo.svg',
  'https://www.cloudways.com/wp-content/uploads/2022/03/cw-partner-cloudflare-logo.svg',
];

const services = {
  title: 'Solution d’hébergement performant👌',
  list: [
    {
      title: 'Digital Ocean Mutualisé',
      image: image1,
    },
    {
      title: 'CloudFlare Cloud',
      image: image1,
    },
    {
      title: 'PHP VPS',
      image: image1,
    },
    {
      title: 'Redis Dédié',
      image: image1,
    },
  ],
};

export const features = {
  title: 'Forteresse digitale',
  description: 'Our unique performance tweaks guarantee nothing but the best!',
  list: [
    {
      title: 'Cryptage SSL et boost CDN',
      subtitle:
        'Intégration de certificats SSL pour sécuriser les échanges de données et utilisons des CDN pour accélérer le chargement des pages tout en améliorant la sécurité globale.',
      icon: HttpsOutlined,
    },
    {
      title: 'Sécurité accrue via pare-feu',
      subtitle:
        'Installation de pare-feux avancés et des systèmes de détection d’intrusion pour protéger contre les accès non autorisés et les menaces en ligne, assurant une défense robuste pour vos données.',
      icon: SecurityOutlined,
    },
    {
      title: 'Surveillance DDoS et audits',
      subtitle:
        'Nous mettons en œuvre des stratégies de prévention des attaques DDoS pour garantir une disponibilité continue et réalisons des audits de sécurité réguliers pour identifier et corriger les vulnérabilités.',
      icon: ShieldOutlined,
    },
  ],
  partners: [],
  bgColor: '#f4f4f4',
  color: color,
  index: 2,
};

const Security = () => {
  return (
    <Box>
      <Landing
        hero={hero}
        color={color}
        partners={partners}
        services={services}
        features={features.index}
      />
    </Box>
  );
};

export default Security;