export const light = {
  alternate: {
    main: '#f7f9fc',
    dark: '#edf1f7',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'light',
  primary: {
    main: '#00AB55',
    light: '#33CC70',
    dark: '#009357',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ffb74d',
    main: '#f9b934',
    dark: '#FF9800',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#2d3748',
    secondary: '#646e73',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#fff',
    default: '#fff',
    level2: '#f5f5f5',
    level1: '#fff',
  },
};

export const dark = {
  alternate: {
    main: '#f7f9fc',
    dark: '#edf1f7',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'dark',
  primary: {
    main: '#00f',
    light: '#00F',
    dark: '#00F',
    contrastText: '#fff',
  },
  secondary: {
    light: '#fed130',
    main: '#fed130',
    dark: '#fed130',
    contrastText: '#2d3748',
  },
  text: {
    primary: '#2d3748',
    secondary: '#646e73',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#fff',
    default: '#fff',
    level2: '#f5f5f5',
    level1: '#fff',
  },
  chipBlue: {
    main: '#0000ff14',
    light: '#0000ff14',
    dark: '#0000ff14',
    contrastText: '#00f',
  },
  chipGreen: {
    main: '#C6F6D9',
    contrastText: '#045726',
  },
  chipYellow: {
    main: '#FFF6D5',
    contrastText: '#706336',
  },
};