import React from 'react';
import Box from '@mui/material/Box';
import Landing from 'common/Landing';
import image1 from 'images/home-anyone.jpg';
import { 
  SpeedOutlined,
  CachedOutlined,
  StorageOutlined,
} from '@mui/icons-material';

const hero = {
  typewriter: [' éprouvé', ' fiable', ' dédié'],
  dot: ',',
  titleLine1: 'Hébergement',
  titleLine2: 'sur tous nos plans',
  text: 'Notre service d’hébergement performant vous libère de la complexité technique : nous prenons en charge la maintenance, les mises à jour et la sécurité de votre infrastructure.',
  cta: '',
  icon: '',
};

const color = '#FF6D15';

const partners = [
  'https://www.cloudways.com/wp-content/uploads/2019/10/cw-partner-gce-logo.svg',
  'https://www.cloudways.com/wp-content/uploads/2019/10/cw-partner-do-logo.svg',
  'https://www.cloudways.com/wp-content/uploads/2019/10/cw-partner-aws-logo.svg',
  'https://www.cloudways.com/wp-content/uploads/2019/10/cw-partner-vultr-logo.svg',
  'https://www.cloudways.com/wp-content/uploads/2019/10/cw-partner-linode-logo.svg',
  'https://www.cloudways.com/wp-content/uploads/2022/03/cw-partner-malcare-logo.svg',
  'https://www.cloudways.com/wp-content/uploads/2022/03/cw-partner-cloudflare-logo.svg',
];

const services = {
  title: 'Solution d’hébergement performant👌',
  list: [
    {
      title: 'Digital Ocean Mutualisé',
      image: image1,
    },
    {
      title: 'CloudFlare Cloud',
      image: image1,
    },
    {
      title: 'PHP VPS',
      image: image1,
    },
    {
      title: 'Redis Dédié',
      image: image1,
    },
  ],
};

export const features = {
  title: 'Accélérateur numérique',
  description: 'Our unique performance tweaks guarantee nothing but the best!',
  list: [
    {
      title: 'Optimisation de la performance',
      subtitle: 'Boost de la vitesse en utilisant une infrastructure serveur robuste et des technologies de pointe, garantissant ainsi une expérience utilisateur fluide et réactive.',
      icon: SpeedOutlined,
    },
    {
      title: 'Stratégies de cache avancées',
      subtitle: 'Mise en œuvre de stratégies de cache sophistiquées, incluant le cache de page entière, le cache d’objets et le cache de navigateur, pour réduire les temps de réponse et améliorer l’efficacité de nos serveurs.',
      icon: CachedOutlined,
    },
    {
      title: 'Gestion des bases de données',
      subtitle: 'Nous utilisons des pratiques de gestion des bases de données efficaces, telles que l’indexation adaptative et la réplication, pour assurer des performances rapides et fiables même sous forte charge.',
      icon: StorageOutlined,
    },
  ],
  partners: [],
  bgColor: '#f4f4f4',
  color: color,
  index: 1,
};

const Hosting = () => {
  return (
    <Box>
      <Landing
        hero={hero}
        color={color}
        partners={partners}
        services={services}
        features={features.index}
      />
    </Box>
  );
};

export default Hosting;