import React, { useRef, useState, useEffect } from 'react';
import { Box, Card, Button, TextField, Typography, Grid, MenuItem } from '@mui/material';
import { ReactComponent as MoitoLogo } from 'svg/logos/Moito-Unix.svg';

const outputWraped = (text, color = 'white', index, customStyles = {}) => (
  <Typography
    variant="body2"
    fontFamily={'JetBrainsMono-Regular'}
    style={{
      color,
      ...customStyles,
      opacity: 0,
      animation: `crt-effect 0.5s ease-in-out forwards`,
      animationDelay: `${index * 0.01}s`,
    }}
  >
    {text}
  </Typography>
);

const outputSeparator = (index) => (
  <span style={{ display: 'block', width: '100%', overflow: 'hidden', whiteSpace: 'pre', wordWrap: 'break-word' }}>
    <Typography
      variant="body2"
      fontFamily={'JetBrainsMono-Regular'}
      style={{
        color: 'lightgreen',
        display: 'inline-block',
        width: '100%',
        opacity: 0,
        animation: `crt-effect 0.5s ease-in-out forwards`,
        animationDelay: `${index * 0.01}s`,
      }}
    >
      {'-'.repeat(40)}
    </Typography>
  </span>
);

const outputBlank = (index) => (
  <span
    style={{
      display: 'block',
      opacity: 0,
      animation: `crt-effect 0.5s ease-in-out forwards`,
      animationDelay: `${index * 0.01}s`,
    }}
  >
    {' '}
  </span>
);

const crtEffectKeyframes = `
@keyframes crt-effect {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
`;

const addCRTAnimation = () => {
  const styleSheet = document.styleSheets[0];
  styleSheet.insertRule(crtEffectKeyframes, styleSheet.cssRules.length);
};

const formFields = [
  { label: 'Nom', name: 'name', type: 'text', xs: 12, sm: 12 },
  { label: 'Courriel', name: 'email', type: 'email', xs: 12 },
  { label: 'Téléphone', name: 'phone', type: 'tel', xs: 12 },
  {
    label: 'Raison',
    name: 'reason',
    type: 'select',
    options: [
      'Demande d\'audit',
      'Demande de renseignement',
      'Support technique',
      'Autre',
    ],
    xs: 12,
  },
  {
    label: 'Description (optionnel)',
    name: 'details',
    type: 'textarea',
    multiline: true,
    rows: 4,
    xs: 12,
  },
];

const ContactFormMobile = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    reason: '',
    details: '',
  });

  useEffect(() => {
    addCRTAnimation();
  }, []);

  const generateInitialTerminalLines = () => [
    outputBlank(0),
    outputWraped('Bienvenue chez Moïto - Solutions en ligne', 'lightgreen', 1),
    outputSeparator(2),
    outputWraped('Des questions, besoin d’aide ou d’un audit ?', 'lightblue', 3),
    outputWraped('Contactez-nous via ce formulaire, nous sommes à votre écoute !', 'lightblue', 4),
    outputBlank(5),
  ];

  const [terminalLineData] = useState(generateInitialTerminalLines());

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      console.log(formData); // Afficher les données du formulaire ici

      if (response.ok) {
        console.log('Email envoyé avec succès');
      } else {
        console.log('Erreur lors de l\'envoi de l\'email');
      }
    } catch (error) {
      console.error('Erreur:', error);
    }
  };

  const handleFieldChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const renderFormFields = () => {
    return formFields.map((field, index) => (
      <Grid item xs={field.xs} sm={field.sm || 12} key={index}>
        {field.type === 'select' ? (
          <TextField
            label={field.label}
            name={field.name}
            select
            value={formData.reason}
            onChange={handleFieldChange}
            fullWidth
            variant="outlined"
            size="medium"
            sx={{
              '& .MuiInputLabel-root': { color: 'lightblue' },
              '& .MuiInputLabel-root.Mui-focused': { color: 'white' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'lightblue' },
                '&:hover fieldset': { borderColor: 'lightblue' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
                '& input::placeholder': { color: 'lightblue' },
                '& .MuiSelect-icon': { color: 'lightblue' },
              },
              '& .MuiInputBase-input': { color: 'lightblue' },
              '& .MuiMenu-paper': { backgroundColor: '#252a33' },
              '& .MuiListItem-root': { color: 'white' },
              pointerEvents: 'auto',
              opacity: 1,
              animation: 'crt-effect 0.5s ease-in-out forwards',
              animationDelay: `${(index + 6) * 0.1}s`,
            }}
          >
            {field.options.map((option, i) => (
              <MenuItem
                key={i}
                value={option}
                sx={{
                  backgroundColor: '#252a33',
                  color: 'lightblue',
                  '&.Mui-selected': {
                    backgroundColor: '#2d3748',
                    '&:hover': { backgroundColor: '#2d3748' },
                  },
                  '&:hover': { backgroundColor: '#33384d' },
                }}
              >
                {option}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <TextField
            label={field.label}
            name={field.name}
            type={field.type}
            multiline={field.multiline || false}
            rows={field.rows || 1}
            fullWidth
            variant="outlined"
            size="medium"
            value={formData[field.name]}
            onChange={handleFieldChange}
            sx={{
              '& .MuiInputLabel-root': { color: 'lightblue' },
              '& .MuiInputLabel-root.Mui-focused': { color: 'white' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'lightblue' },
                '&:hover fieldset': { borderColor: 'lightblue' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
                '& input::placeholder': { color: 'lightblue' },
              },
              '& .MuiInputBase-input': { color: 'lightblue' },
              pointerEvents: 'auto',
              opacity: 1,
              animation: 'crt-effect 0.5s ease-in-out forwards',
              animationDelay: `${(index + 6) * 0.1}s`,
            }}
          />
        )}
      </Grid>
    ));
  };

  return (
    <>
      <Box
        sx={{
          animation: 'crt-effect 0.5s ease-in-out forwards',
          animationDelay: '0.1s',
          marginBottom: '32px',
          opacity: 0,
        }}
      >
        <MoitoLogo style={{ height: 'auto', width: '100%' }} />
      </Box>
      <Box>
        {terminalLineData.map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </Box>
      <Box
        padding={0}
        width={'100%'}
        component={Card}
        borderRadius={0}
        boxShadow={0}
        elevation={0}
        marginY={4}
        bgcolor={'#252a33'}
      >
        <Box
          sx={{
            position: 'relative',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: '-6px',
              bottom: '-6px',
              left: '-6px',
              right: '-6px',
              zIndex: 0,
              background:
                'linear-gradient(-45deg, transparent 45%, lightgreen 45%, lightgreen 55%, transparent 55%) 0 0 / 10px 10px, linear-gradient(-45deg, transparent 45%, lightgreen 45%, lightgreen 55%, transparent 55%) 5px 0px / 10px 10px',
              backgroundColor: '#252a33',
              animation: 'crt-effect 0.5s ease-in-out forwards',
              animationDelay: '0.1s',
              opacity: 0,
            },
          }}
        >
          <form
            noValidate
            autoComplete="off"
            ref={form}
            onSubmit={sendEmail}
            style={{
              position: 'relative',
              zIndex: 1,
              background: '#252a33',
              margin: '4px',
              padding: '2px',
            }}
          >
            <Grid container spacing={4} padding={4}>
              {renderFormFields()}
              <Grid item xs={12}>
                <Button
                  sx={{
                    height: 54,
                    animation: 'crt-effect 0.5s ease-in-out forwards',
                    animationDelay: `${(formFields.length + 7) * 0.1}s`,
                    opacity: 0,
                  }}
                  variant="contained"
                  color="success"
                  size="medium"
                  fullWidth
                  type="submit"
                >
                  Envoyer
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
      <Box>
        <Typography color="lightblue" align={'center'}>
          Nous répondons généralement dans les 24 heures, jours ouvrables.
        </Typography>
      </Box>
    </>
  );
};

export default ContactFormMobile;