import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Mediation, AllInclusive } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(() => ({
  backgroundColor: '#f4f4f4',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  minHeight: '180px',
  borderRadius: '15px',
  padding: '20px',
  textAlign: 'center',
}));

const LogoCards = () => {
  const features = [
    { title: 'Plateformes illimitées', description: 'Accédez à une infinité de plateformes pour vos besoins d\'automatisation.' },
    { title: 'Scénarios illimités', description: 'Créez autant de scénarios que vous le souhaitez, sans aucune restriction.' },
    { title: 'Stockage illimité', description: 'Profitez d\'un stockage illimité pour tous vos fichiers et données.' },
    { title: 'Exécutions illimitées', description: 'Exécutez vos automatisations aussi souvent que nécessaire, sans limites.' },
    { title: 'Support 24/7', description: 'Bénéficiez d\'un support client illimité, disponible à toute heure.' },
    { title: 'Flexibilité totale', description: 'Personnalisez chaque aspect de votre automatisation avec une flexibilité illimitée.' },
  ];

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
        mb={10}
      >
        <Mediation sx={{ fontSize: { sm: '5rem', md: '8rem' }, rotate: '90deg' }} />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid
            size={{ xs: 12, sm: 6 }}
            pb={1}
            sx={{
              position: 'sticky',
              top: '78px',
              alignSelf: 'flex-start',
              background: '#fff',
            }}
          >
            <Typography variant="h2">
              Atteignez une efficacité maximale avec des options illimitées
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }} container spacing={2}>
            {features.map((feature, i) => (
              <Grid key={i} size={{ sm: 12, md: 6 }} width={'100%'}>
                <Item elevation={0}>
                  <AllInclusive sx={{ fontSize: '3rem', color: '#7209b7' }} />
                  <Typography variant="h6" fontWeight="bold" mt={2}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" mt={1}>
                    {feature.description}
                  </Typography>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default LogoCards;