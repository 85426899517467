import React, { useState, useEffect, useRef } from 'react';
import { useMobile } from 'MobileContext';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Terminal, { ColorMode, TerminalOutput } from 'react-terminal-ui';
import { ReactComponent as MoitoLogo } from 'svg/logos/Moito-Unix.svg';
import { v4 as uuidv4 } from 'uuid';

const crtEffectKeyframes = `
@keyframes crt-effect {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}`;

const addCRTAnimation = () => {
  const styleSheet = document.styleSheets[0];
  styleSheet.insertRule(crtEffectKeyframes, styleSheet.cssRules.length);
};

let globalIndex = 0;

const outputWraped = (text, color = 'white', disableDelay = false, customStyles = {}) => {
  const localIndex = disableDelay ? 0 : globalIndex++;

  return (
    <span
      key={uuidv4()}
      style={{
        display: 'block',
        animation: `crt-effect 0.5s ease-in-out forwards`,
        animationDelay: `${localIndex * 0.1}s`,
        opacity: 0,
      }}
    >
      <TerminalOutput>
        <span style={{ color, whiteSpace: 'pre-wrap', wordWrap: 'break-word', ...customStyles }}>
          {text}
        </span>
      </TerminalOutput>
    </span>
  );
};

const outputSeparator = () => {
  const localIndex = globalIndex++;
  return (
    <span
      key={uuidv4()}
      style={{
        display: 'block',
        width: '100%',
        animation: `crt-effect 0.5s ease-in-out forwards`,
        animationDelay: `${localIndex * 0.1}s`,
        opacity: 0,
      }}
    >
      <TerminalOutput>
        <span style={{ color: 'lightgreen', display: 'inline-block', width: '100%' }}>
          {'-'.repeat(40)}
        </span>
      </TerminalOutput>
    </span>
  );
};

const outputBlank = () => {
  const localIndex = globalIndex++;
  return (
    <span
      key={uuidv4()}
      style={{
        display: 'block',
        animation: `crt-effect 0.5s ease-in-out forwards`,
        animationDelay: `${localIndex * 0.1}s`,
        opacity: 0,
      }}
    >
      <TerminalOutput> </TerminalOutput>
    </span>
  );
};

const TerminalController = () => {
  const isMobile = useMobile();

  const lastLineRef = useRef(null);
  const setInput = useState('');

  const [step, setStep] = useState(0);
  const [promptText, setPromptText] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [reason, setReason] = useState('');
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  useEffect(() => {
    switch (step) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        setPromptText('>');
        break;
      case 6:
        setPromptText('> Traitement...');
        break;
      default:
        setPromptText('>>');
        break;
    }
  }, [step]);

  useEffect(() => {
    addCRTAnimation();
  }, []);

  const generateInitialTerminalLines = () => {
    globalIndex = 0;
    return [
      <TerminalOutput key='moito-logo'>
        <Box
          marginY={4}
          sx={{
            display: 'inline-block',
            animation: 'crt-effect 0.5s ease-in-out forwards',
            animationDelay: '0.1s',
            opacity: 0,
          }}
        >
          <MoitoLogo style={{ height: '200px', width: '100%' }} />
        </Box>
      </TerminalOutput>,
      outputBlank(),
      outputWraped('Bienvenue chez Moïto - Solutions en ligne', 'lightgreen'),
      outputSeparator(),
      outputWraped('Des questions, besoin d’aide ou d’un audit ?', 'lightblue'),
      outputWraped('Contactez-nous via ce formulaire, nous sommes à votre écoute !', 'lightblue'),
      outputBlank(),
      outputSeparator(),
      outputBlank(),
      outputWraped('Quel est votre nom?', 'lightblue', true),
    ];
  };

  const [terminalLineData, setTerminalLineData] = useState(generateInitialTerminalLines());

  useEffect(() => {
    setTerminalLineData(generateInitialTerminalLines());
  }, [isMobile]);

  useEffect(() => {
    if (lastLineRef.current) {
      lastLineRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [terminalLineData]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const key = event.key;

      if (key.length === 1) {
        setInput((prev) => prev + key);
        setIsNextDisabled(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const resetForm = () => {
    setStep(0);
    setInput('');
    setEmail('');
    setName('');
    setPhone('');
    setReason('');
    setTerminalLineData(generateInitialTerminalLines());
  };

  const isValidEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const formatPhoneNumber = (input) => {
    const cleaned = input.replace(/\D/g, '');
    const limited = cleaned.slice(0, 10);

    const formatted = limited.padEnd(10, ' ').replace(/(\d{3})(\d{3})(\d{0,4})/, (match, p1, p2, p3) => {
      return `${p1}-${p2}-${p3}`.trim();
    });

    return formatted;
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    return cleaned.length === 10;
  };

  const sendMessage = async (messageData) => {
    const successMessage = outputWraped(
      'Merci pour votre message ! 🚀 Nous reviendrons vers vous dans les plus brefs délais.',
      'lightgreen'
    );

    const errorMessage = (text) => outputWraped(text, 'red');

    try {
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(messageData),
      });

      if (response.ok) {
        setTerminalLineData((prevData) => [...prevData, successMessage]);
      } else {
        const errorText = await response.text();
        setTerminalLineData((prevData) => [
          ...prevData,
          errorMessage(`Erreur lors de l'envoi du message: ${errorText || 'Erreur inconnue'}`),
        ]);
      }
    } catch (error) {
      setTerminalLineData((prevData) => [
        ...prevData,
        errorMessage('Erreur de connexion. Veuillez vérifier votre connexion Internet.'),
      ]);
    }
  };

  const reasons = [
    'Demande d\'audit',
    'Demande de renseignement',
    'Support technique',
    'Autre',
  ];

  const boolean = ['Oui', 'Non'];

  const handleInput = (terminalInput) => {
    let newLines = [
      ...terminalLineData,
      <TerminalOutput key={uuidv4()}>
        <span style={{ color: 'white' }}>{`$ ${terminalInput}`}</span>
      </TerminalOutput>,
    ];

    if (step === 0) {
      setName(terminalInput);
      newLines.push(outputBlank());
      newLines.push(outputWraped(`Bonjour ${terminalInput}!`, 'lightgreen', true));
      newLines.push(outputBlank());
      newLines.push(outputWraped('Quel est votre courriel pour que nous puissions vous répondre ?', 'lightblue', true));
      setStep(1);
    } else if (step === 1) {
      if (isValidEmail(terminalInput)) {
        setEmail(terminalInput);
        newLines.push(outputBlank());
        newLines.push(outputWraped('Quel est votre numéro de téléphone pour que nous puissions vous joindre ?', 'lightblue', true));
        setStep(2);
      } else {
        newLines.push(outputWraped('Adresse e-mail invalide. Veuillez réessayer :', 'red', true));
      }
    } else if (step === 2) {
      const formattedPhoneNumber = formatPhoneNumber(terminalInput);
      if (isValidPhoneNumber(formattedPhoneNumber)) {
        setPhone(formattedPhoneNumber);
        newLines.push(outputBlank());
        newLines.push(outputWraped('Quelle est la raison de votre demande ?', 'lightblue', true));
        newLines.push(outputWraped('Veuillez sélectionner une option en entrant le numéro correspondant :', 'yellow', true));
        reasons.forEach((r, index) => {
          newLines.push(outputWraped(`[${index + 1}] ${r}`, 'lightblue', true));
        });
        setStep(3);
      } else {
        newLines.push(outputWraped('Numéro de téléphone invalide. Veuillez entrer à nouveau :', 'red', true));
      }
    } else if (step === 3) {
      const selectedReasonIndex = parseInt(terminalInput, 10) - 1;
      if (selectedReasonIndex >= 0 && selectedReasonIndex < reasons.length) {
        setReason(reasons[selectedReasonIndex]);
        newLines.push(outputBlank());
        newLines.push(outputWraped(`Vous avez sélectionné : ${reasons[selectedReasonIndex]}`, 'lightgreen', true));

        if (selectedReasonIndex === reasons.length - 1) {
          newLines.push(outputWraped('Veuillez préciser les détails de votre demande :', 'lightblue', true));
          setStep(5);
        } else {
          newLines.push(outputWraped('Souhaitez-vous ajouter des précisions à votre demande ?', 'lightblue', true));
          boolean.forEach((b, index) => {
            newLines.push(outputWraped(`[${index + 1}] ${b}`, 'lightblue', true));
          });
          setStep(4);
        }
      } else {
        newLines.push(outputWraped('Sélection invalide, veuillez entrer un numéro correct :', 'red', true));
      }
    } else if (step === 4) {
      const selectedBooleanIndex = parseInt(terminalInput, 10) - 1;
      if (selectedBooleanIndex === 0) {
        newLines.push(outputWraped('Veuillez fournir plus de détails sur votre demande :', 'lightblue', true));
        setStep(5);
      } else if (selectedBooleanIndex === 1) {
        newLines.push(outputBlank());
        newLines.push(outputWraped('Merci, votre demande a bien été enregistrée.', 'lightgreen', true));
        const messageData = {
          email,
          name,
          phone,
          reason,
          detail: 'Aucune information supplémentaire',
        };
        sendMessage(messageData);
        setStep(6);
      } else {
        newLines.push(outputWraped('Sélection invalide, veuillez entrer 1 pour Oui ou 2 pour Non :', 'red', true));
      }
    } else if (step === 5) {
      newLines.push(outputBlank());
      newLines.push(outputWraped('Merci pour ces informations supplémentaires.', 'lightgreen', true));
      const messageData = {
        email,
        name,
        phone,
        reason,
        detail: terminalInput,
      };
      sendMessage(messageData);
      setStep(6);
    }

    setTerminalLineData(newLines);
    setInput('');
  };

  const handleTerminalInput = (terminalInput) => {
    if (terminalInput.trim() !== '') {
      handleInput(terminalInput);
    }
  };

  const handleNext = () => {
    const hiddenInput = document.querySelector('.terminal-hidden-input');
    const terminalWrapper = document.querySelector('#modal');

    if (hiddenInput) {
      const inputValue = hiddenInput.value.trim();

      if (inputValue !== '') {
        const event = new KeyboardEvent('keydown', {
          key: 'Enter',
          code: 'Enter',
          keyCode: 13,
          which: 13,
          bubbles: true,
        });
        hiddenInput.dispatchEvent(event);
      } else {
        terminalWrapper.classList.add('terminal-flash');
        setTimeout(() => {
          terminalWrapper.classList.remove('terminal-flash');
        }, 100);
      }
      hiddenInput.focus();
    } else {
      console.log('Hidden input not found');
    }
  };

  const handleResetForm = () => {
    const hiddenInput = document.querySelector('.terminal-hidden-input');
    resetForm();
    hiddenInput.focus();
  };

  return (
    <Box pl={3}>
      <Terminal
        prompt={promptText}
        name='Terminal'
        colorMode={ColorMode.Dark}
        height={'calc(100vh - 80px)'}
        onInput={handleTerminalInput}
        startingInputValue={''}
      >
        {terminalLineData}
      </Terminal>
      <Box
        sx={{
          position: 'fixed',
          bottom: '24px',
          left: '24px',
          display: 'flex',
          gap: '10px',
          zIndex: 1000,
        }}
      >
        <Button
          variant='contained'
          color='success'
          onClick={handleNext}
          disabled={isNextDisabled}
        >
          {step === 4 ? 'Envoyer' : 'Suivant'}
        </Button>
        <Button variant='outlined' color='error' onClick={handleResetForm}>
          Réinitialiser
        </Button>
      </Box>
    </Box>
  );
};

export default TerminalController;