import React from 'react';
import Box from '@mui/material/Box';
import Landing from 'common/Landing';
import image1 from 'images/home-anyone.jpg';
import { UpdateOutlined, BackupOutlined, BugReportOutlined } from '@mui/icons-material';

const hero = {
  typewriter: [' agile'],
  dot: ',',
  titleLine1: 'Gestion proactive',
  titleLine2: 'pour votre Wordpress',
  text: 'Renforcez l\'agilité de votre gestion avec le soutien d\'une équipe d\'experts qui assure une maintenance optimisée de vos services.',
  cta: '',
  icon: '',
};

const color = '#16AB59';

const partners = [
  'https://www.cloudways.com/wp-content/uploads/2019/10/cw-partner-gce-logo.svg',
  'https://www.cloudways.com/wp-content/uploads/2019/10/cw-partner-do-logo.svg',
  'https://www.cloudways.com/wp-content/uploads/2019/10/cw-partner-aws-logo.svg',
  'https://www.cloudways.com/wp-content/uploads/2019/10/cw-partner-vultr-logo.svg',
  'https://www.cloudways.com/wp-content/uploads/2019/10/cw-partner-linode-logo.svg',
  'https://www.cloudways.com/wp-content/uploads/2022/03/cw-partner-malcare-logo.svg',
  'https://www.cloudways.com/wp-content/uploads/2022/03/cw-partner-cloudflare-logo.svg',
];

const services = {
  title: 'Solution d’hébergement performant👌',
  list: [
    {
      title: 'Digital Ocean Mutualisé',
      image: image1,
    },
    {
      title: 'CloudFlare Cloud',
      image: image1,
    },
    {
      title: 'PHP VPS',
      image: image1,
    },
    {
      title: 'Redis Dédié',
      image: image1,
    },
  ],
};

export const features = {
  title: 'Tuning haute performance',
  description: 'Our unique performance tweaks guarantee nothing but the best!',
  list: [
    {
      title: 'Gestion dynamique des mises à jour',
      subtitle:
        'Application régulière des dernières mises à jour de sécurité et de fonctionnalités pour assurer une plateforme stable et sécurisée, réduisant ainsi les risques de vulnérabilités.',
      icon: UpdateOutlined,
    },
    {
      title: 'Sauvegarde et restauration',
      subtitle:
        'Nous effectuons des sauvegardes automatiques quotidiennes pour une récupération rapide et fiable en cas de perte de données accidentelle ou de défaillance système, garantissant la continuité de vos activités.',
      icon: BackupOutlined,
    },
    {
      title: 'Résolution proactive d’erreurs',
      subtitle:
        'Déploiement de techniques proactives pour détecter et résoudre les erreurs, tout en effectuant des optimisations constantes pour maintenir des performances élevées et une disponibilité maximale du service.',
      icon: BugReportOutlined,
    },
  ],
  partners: [],
  bgColor: '#00f',
  color: color,
  index: 3,
};

const Maintenance = () => {
  return (
    <Box>
      <Landing hero={hero} color={color} partners={partners} services={services} features={features.index} />
    </Box>
  );
};

export default Maintenance;