import React from 'react';
import { Card, CardMedia, CardContent, Typography, Grid, Container, Box } from '@mui/material';
import image1 from 'images/rocket.webp';
import image2 from 'images/mixer.webp';
import image3 from 'images/security2.webp';
import image4 from 'images/keyboard6.webp';

const featuresData = [
  { title: 'Hébergement puissant', icon: image1 },
  { title: 'Maintenance Optimisée', icon: image2 },
  { title: 'Cybersécurité renforcée', icon: image3 },
  { title: 'Développement sur mesure', icon: image4 }
];

const Features = () => {
  return (
    <>
      <Box mb={11}>
        <Typography variant="h2" color={'#fff'} textAlign={'center'} gutterBottom>
          Gestion intégrée, sans compromis 👌
        </Typography>
      </Box>
      <Container>
        <Grid container spacing={8}>
          {featuresData.map((feature, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  padding: '2rem',
                  borderRadius: '2rem',
                  overflow: 'hidden',
                  backgroundColor: 'white',
                  position: 'relative',
                  height: 0,
                  paddingBottom: '70%',
                }}
              >
                <CardContent
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    padding: { sm: '1.5rem', md: '2rem' }
                  }}
                >
                  <Typography variant="h3" mb={0} sx={{ whiteSpace: 'pre-line' }}>
                    {feature.title}
                  </Typography>
                </CardContent>
                <CardMedia
                  component="img"
                  image={feature.icon}
                  alt={feature.title}
                  sx={{
                    position: 'absolute',
                    top: {
                      xs: '5rem',
                      sm: '-3rem',
                      md: '7rem',
                      lg: '8rem'
                    },
                    height: {
                      xs: '82vw',
                      sm: '82vw',
                      md: '40vw',
                      lg: '500px'
                    },
                    objectFit: 'contain',
                    width: '100%',
                    maxWidth: '100%',
                  }}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default Features;