import React from 'react';
import { Typography, Box } from '@mui/material';
import Container from 'common/Container';

const PrivacyPolicy = () => {
  return (
    <Container>
      {/* Titre principal */}
      <Typography variant="h4" gutterBottom>
        Politique de confidentialité de Moïto
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Date d’entrée en vigueur : 1 septembre 2024
      </Typography>

      {/* Introduction */}
      <Typography variant="body1" paragraph>
        Moïto s&apos;engage à protéger votre vie privée. Cette politique de confidentialité explique comment nous recueillons,
        utilisons, divulguons et protégeons vos informations personnelles lorsque vous visitez ou utilisez notre site web,
        ainsi que vos droits en matière de confidentialité.
      </Typography>

      {/* Section 1: Informations que nous recueillons */}
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          1. Informations que nous recueillons
        </Typography>
        <Typography variant="body1" paragraph>
          Nous pouvons recueillir les informations suivantes :
        </Typography>
        <Typography variant="body1" component="ul">
          <li>Informations personnelles : nom, adresse, courriel, numéro de téléphone, et détails de paiement que vous nous fournissez volontairement.</li>
          <li>Informations de navigation : adresses IP, types de navigateurs, pages visitées, et autres données collectées automatiquement lorsque vous utilisez notre service.</li>
        </Typography>
      </Box>

      {/* Section 2: Utilisation de vos informations */}
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          2. Utilisation de vos informations
        </Typography>
        <Typography variant="body1" component="ul">
          <li>Fournir, exploiter et améliorer nos services.</li>
          <li>Personnaliser votre expérience et répondre à vos demandes.</li>
          <li>Analyser l&apos;utilisation de nos services pour améliorer nos offres.</li>
        </Typography>
      </Box>

      {/* Section 3: Partage de vos informations */}
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          3. Partage de vos informations
        </Typography>
        <Typography variant="body1" paragraph>
          Vos informations personnelles ne seront partagées que dans les cas suivants :
        </Typography>
        <Typography variant="body1" component="ul">
          <li>
            Avec des partenaires comme les centres de données et fournisseurs d’hébergement de données, les plateformes de traitement de paiement,
            les fournisseurs de plateformes logiciel, etc.
          </li>
          <li>Pour se conformer à des obligations légales.</li>
          <li>Pour protéger les droits ou la propriété de Moïto.</li>
        </Typography>
      </Box>

      {/* Section 4: Sécurité de vos informations */}
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          4. Sécurité de vos informations
        </Typography>
        <Typography variant="body1" paragraph>
          Nous prenons des mesures de sécurité pour protéger vos informations contre l&apos;accès non autorisé, la modification,
          la divulgation ou la destruction.
        </Typography>
      </Box>

      {/* Section 5: Vos droits */}
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          5. Vos droits
        </Typography>
        <Typography variant="body1" component="ul">
          <li>Accéder, corriger ou supprimer vos informations personnelles.</li>
          <li>Retirer votre consentement à tout moment.</li>
          <li>Déposer une plainte auprès d&apos;une autorité compétente.</li>
        </Typography>
      </Box>

      {/* Section 6: Modifications de cette politique */}
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          6. Modifications de cette politique
        </Typography>
        <Typography variant="body1" paragraph>
          Nous pouvons mettre à jour cette politique de confidentialité. Toute modification sera communiquée sur notre site web.
        </Typography>
      </Box>

      {/* Section 7: Contactez-nous */}
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          7. Contactez-nous
        </Typography>
        <Typography variant="body1" paragraph>
          Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter à :{' '}
          <a href="mailto:hello@moito.ca">hello@moito.ca</a>.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;