import React from 'react';
import Slider from 'react-slick';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

const importAllImages = (requireContext) => {
  return requireContext.keys().slice(0, 30).map(requireContext);
};

const images = importAllImages(require.context('../images/icons', false, /\.(png|jpe?g|svg)$/));

const logosSet = [];
for (let i = 0; i < images.length; i += 3) {
  logosSet.push(images.slice(i, i + 3));
}

const SlotMachine = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const baseSettings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    vertical: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    arrows: false,
    centerMode: true,
  };

  const carousels = [
    { ...baseSettings },
    { ...baseSettings, rtl: true },
    { ...baseSettings },
    { ...baseSettings, rtl: true },
    { ...baseSettings },
    { ...baseSettings, rtl: true },
    { ...baseSettings },
    { ...baseSettings, rtl: true },
    { ...baseSettings },
    { ...baseSettings, rtl: true },
  ];

  let visibleCarousels;
  if (isSmallScreen) {
    visibleCarousels = 2;
  } else if (isMediumScreen) {
    visibleCarousels = 4;
  } else {
    visibleCarousels = 6;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
      {carousels.slice(0, visibleCarousels).map((settings, index) => (
        <React.Fragment key={index}>
          <Box
            sx={{
              width: 100,
              margin: '0 10px',
              position: 'relative',
              overflow: 'hidden',
              '&:before, &:after': {
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                height: '20px',
                background: 'linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0))',
                zIndex: 1,
              },
              '&:after': {
                top: 'unset',
                bottom: 0,
                background: 'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0))',
              },
            }}
          >
            <Slider {...settings}>
              {logosSet[index].map((logo, idx) => (
                <Box
                  key={idx}
                  className='carousel-item'
                  sx={{ padding: 2, textAlign: 'center' }}
                >
                  <img
                    src={logo}
                    alt={`Logo ${idx + 1}`}
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                </Box>
              ))}
            </Slider>
          </Box>
        </React.Fragment>
      ))}
    </Box>
  );
};

export default SlotMachine;